<mat-toolbar class="app-toolbar" color="primary">
    <mat-icon>bar_chart</mat-icon>
    <span>
        <a [routerLink]="['/home']">
            Tichu Tracker
        </a>
    </span>
    <span class="spacer"></span>
    <div *ngIf="authState$ | async as user">
        <button mat-button>
            {{user.displayName}} <mat-icon>account_circle</mat-icon>
        </button>
        <button mat-icon-button (click)="signOut()">
            <mat-icon>login</mat-icon>
        </button>
    </div>
</mat-toolbar>

