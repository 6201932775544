<div class="center">
    <mat-card>
        <mat-card-header>
            <mat-card-title>
                Register for Tichu Tracker
            </mat-card-title>
        </mat-card-header>
        <mat-card-content>
            <form [formGroup]="registrationForm" autocomplete="off">
                <mat-form-field appearance="outline" color="accent">
                    <mat-label>Username</mat-label>
                    <input matInput placeholder="john_doe" formControlName="username" required>
                    <mat-icon matPrefix>account_circle</mat-icon>
                </mat-form-field>
                <mat-form-field appearance="outline" color="accent">
                    <mat-label>Email</mat-label>
                    <input matInput placeholder="john.doe@example.com" formControlName="email" required>
                    <mat-icon matPrefix>email</mat-icon>
                </mat-form-field>
                <mat-form-field appearance="outline" color="accent">
                    <mat-label>Password</mat-label>
                    <input matInput [type]="hidePassword ? 'password' : 'text'" formControlName="password" required>
                    <mat-icon matPrefix>https</mat-icon>
                    <button mat-icon-button matSuffix (click)="hidePassword = !hidePassword">
                        <mat-icon>{{hidePassword ? 'visibility_off' : 'visibility'}}</mat-icon>
                    </button>
                </mat-form-field>
            </form>
            <p class="errorMessage" *ngIf="errorMessage.length > 0">
                {{errorMessage}}
            </p>
            <div class="button-container">
                <button mat-raised-button color="primary" (click)="registerUser()">Register</button>
            </div>
        </mat-card-content>
    </mat-card>
</div>